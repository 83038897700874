import Immutable from 'seamless-immutable';

export const OUTCOMES_FEATURE_FLAGS = Immutable({
  ENABLE_AGGREGATE_ASSESSMENT_VIEW: 'enableAggregateAssessmentView',
  ALLOW_EDIT_RESPONSE: 'allowEditResponse',
  IS_BIG_CLIENT: 'isBigClient',
});

export const OUTCOMES_ERROR_CODE = Immutable({
  ASSESSMENT_CLONE_CURRENT_REASSESSMENTS_EXIST_ERROR:
    'ASSESSMENT_CLONE_CURRENT_REASSESSMENTS_EXIST_ERROR',
});

export const OUTCOMES_USER_PORTAL_VIEW_TYPE = Immutable({
  AGGREGATE_ASSESSMENT: 1,
});

export const DYNAMIC_FIELD_ID = Immutable({
  HEIGHT_FEET: 'heightFeet',
  HEIGHT_INCHES: 'heightInches',
  WEIGHT: 'weight',
  PHONE_TYPE: 'phoneType',
  PHONE_NUMBER: 'phoneNumber',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
});

export const INTERNAL_DEFINITIONS = Immutable({
  PATIENT_GROUP_BIRTHDAY_AND_AGE: {
    uri: 'internal#Patient.intakeGroup.birthdayAndAge',
    resource: 'Patient',
  },
  PATIENT_GROUP_HEIGHT_WEIGHT_AND_BMI: {
    uri: 'internal#Patient.intakeGroup.heightWeightAndBMI',
    resource: 'Patient',
  },
  PATIENT_GROUP_NAME: {
    uri: 'internal#Patient.intakeGroup.name',
    resource: 'Patient',
  },
  PATIENT_GROUP_TELECOM_PHONE: {
    uri: 'internal#Patient.intakeGroup.telecomPhone',
    resource: 'Patient',
  },
  PATIENT_AGE: {
    uri: 'internal#Patient.birthday:age',
    resource: 'Patient',
  },
  PATIENT_BMI: {
    uri: 'internal#Patient.bmi',
    resource: 'Patient',
  },
  PATIENT_INSURANCE_TYPE: {
    uri: 'internal#Patient.insuranceType',
    resource: 'Patient',
  },
  PATIENT_REGION_OF_INJURY: {
    uri: 'internal#Patient.regionOfInjury',
    resource: 'Patient',
  },
  PATIENT_HEIGHT_FEET: {
    uri: 'internal#Patient.height:feet',
    resource: 'Patient',
  },
  PATIENT_HEIGHT_INCHES: {
    uri: 'internal#Patient.height:inches',
    resource: 'Patient',
  },
  PATIENT_WEIGHT: {
    uri: 'internal#Patient.weight',
    resource: 'Patient',
  },
  PATIENT_TELECOM_PHONE_TYPE: {
    uri: 'internal#Patient.telecom.system:phoneType',
    resource: 'Patient',
  },
  PATIENT_TREATMENT_TYPE: {
    uri: 'internal#Patient.treatmentType',
    resource: 'Patient',
  },
  PATIENT_CHRONICITY: {
    uri: 'internal#Patient.chronicity',
    resource: 'Patient',
  },
});

export const GLOBAL_DEFINITIONS = Immutable({
  PATIENT_ADDRESS_POSTAL_CODE: {
    uri: 'http://hl7.org/fhir/StructureDefinition/Patient#Patient.address.postalCode',
    resource: 'Patient',
  },
  PATIENT_BIRTHDAY: {
    uri: 'http://hl7.org/fhir/StructureDefinition/Patient#Patient.birthday',
    resource: 'Patient',
  },
  PATIENT_GENDER: {
    uri: 'http://hl7.org/fhir/StructureDefinition/Patient#Patient.gender',
    resource: '',
  },
  PATIENT_NAME_GIVEN_NAME: {
    uri: 'http://hl7.org/fhir/StructureDefinition/Patient#Patient.name:given',
    resource: 'Patient',
  },
  PATIENT_NAME_FAMILY_NAME: {
    uri: 'http://hl7.org/fhir/StructureDefinition/Patient#Patient.name:family',
    resource: 'Patient',
  },
  PATIENT_TELECOM_EMAIL: {
    uri: 'http://hl7.org/fhir/StructureDefinition/Patient#Patient.telecom.system:email',
    resource: 'Patient',
  },
  PATIENT_TELECOM_PHONE: {
    uri: 'http://hl7.org/fhir/StructureDefinition/Patient#Patient.telecom.system:phone',
    resource: 'Patient',
  },
});

export const EXTENSIONS_INTERNAL = Immutable({
  QUESTIONNAIRE_ITEM_ANSWER_RED_FLAG: {
    url: 'internal/questionnaire-item-answer-red-flag',
    displayName: 'Internal Questionnaire Item Red Flag Answer',
  },
});

export const PROMIS_SYSTEM = Immutable(
  'http://www.healthmeasures.net/explore-measurement-systems/promis'
);

export const AssessmentSnackbarConfigs = Immutable({
  variant: 'success',
  anchorOrigin: { horizontal: 'center', vertical: 'top' },
  preventDuplicate: true,
});

export const LANGUAGE_CODES = Immutable({
  en: {
    code: 'en',
    display: 'English',
  },
  es: {
    code: 'es',
    display: 'Spanish',
  },
});

export const DISTRIBUTION_PARTICIPANT_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
};

export const DISTRIBUTION_PARTICIPANT_END_REASON = {
  PATIENT_REQUEST: 1,
  CASE_NOT_KEPT: 2,
  NEW_CASE_STARTED: 3,
  OTHER: 4,
};

export const DISTRIBUTION_PARTICIPANT_END_REASON_OPTIONS = [
  {
    value: DISTRIBUTION_PARTICIPANT_END_REASON.PATIENT_REQUEST,
    label: 'Patient Request',
  },
  {
    value: DISTRIBUTION_PARTICIPANT_END_REASON.CASE_NOT_KEPT,
    label: 'Case Not Kept',
  },
  {
    value: DISTRIBUTION_PARTICIPANT_END_REASON.NEW_CASE_STARTED,
    label: 'New Case Started',
  },
  { value: DISTRIBUTION_PARTICIPANT_END_REASON.OTHER, label: 'Other' },
];

export const SUB_SCORES_DEFAULT_SHOW_RAW_SCORE = true;
export const SUB_SCORES_DEFAULT_SHOW_AVG_SCORE = true;
export const SUB_SCORES_DEFAULT_SHOW_TOTAL_SCORE = true;
export const SUB_SCORES_DEFAULT_RAW_SCORE_LABEL = 'Raw Score';
export const SUB_SCORES_DEFAULT_AVG_SCORE_LABEL = 'Avg Score';
